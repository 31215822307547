









import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      tableOptions: null,
      item: null,
    };
  },
  methods: {
    makeTableOptions(item) {
      const item2 = item;

      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Khách hàng sỉ",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              code: {
                attrs: {
                  label: "Mã khách hàng",
                },
                rules: {
                  match: {},
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              mktTeamId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Team",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktDepartmentId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (>=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (<=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          displayFields: {
            code: {
              text: "Mã khách hàng",
            },
            name: {
              text: "Tên khách hàng",
            },
            address: {
              text: "Địa chỉ",
            },
            phoneNumber: {
              text: "Số điện thoại",
            },
            mktUser: {
              text: "User MKT",
              options: {
                subProp: "mktUser.name",
                sortBy: "mktUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.mktUser && item.mktUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            mktTeam: {
              text: "Team",
              options: {
                subProp: "mktTeam.name",
                sortBy: "mktTeamId",
              },
            },
            mktDepartment: {
              text: "Phòng",
              options: {
                subProp: "mktDepartment.name",
                sortBy: "mktDepartmentId",
              },
            },
            mktPlatform: {
              text: "Nền tảng MKT",
              options: {
                subProp: "mktPlatform.name",
                sortBy: "mktPlatformId",
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              hidden: true,
              options: {
                filter: "dateTime",
              },
            },
            description: {
              text: "Ghi chú",
            },
            action: {},
          },
          topActionButtons: {
            insert: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker({ code }) {
                        return code;
                      },
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: item._id,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("wholesaleCustomers", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              item.marketId = item2._id;
              return await coreApiClient.call("wholesaleCustomers", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "wholesaleCustomers",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("wholesaleCustomers", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              code: {
                attrs: {
                  label: "Mã khách hàng",
                  required: true,
                },
              },
              name: {
                attrs: {
                  label: "Tên khách hàng",
                  required: true,
                },
              },
              address: {
                attrs: {
                  label: "Địa chỉ",
                },
              },
              phoneNumber: {
                attrs: {
                  label: "Số điện thoại",
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              mktPlatformId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Ghi chú",
                },
              },
            },
            insertForm: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
              content: {
                fieldNames: ["code", "name", "address", "phoneNumber", "mktUserId", "mktPlatformId", "description"],
              },
            },
            editForm: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
              content: {
                fieldNames: ["code", "name", "address", "phoneNumber", "mktUserId", "mktPlatformId", "description"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "code",
            },
          },
        },
      };
    },
  },
  async created() {
    this.item = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.item);
  },
});
